import request from '@/auth/jwt/useJwt'

/*  查询分组列表  */
// eslint-disable-next-line arrow-body-style
export function query() {
  return request.axiosIns({
    url: 'device/group/_query/tree?paging=false',
    method: 'get',
  })
}
/* 新增分组信息  */
export function add(data) {
  return request.axiosIns({
    url: 'device/group',
    method: 'post',
    data,
  })
}
/* 编辑分组信息 */
export function edit(data) {
  return request.axiosIns({
    url: 'device/group',
    method: 'patch',
    data,
  })
}
/* 删除分组 */
export function remove(id) {
  return request.axiosIns({
    url: `device/group/${id}`,
    method: 'DELETE',
  })
}
/* 根据分组ID获取设备 */
export function getdeviceListByGroupId(id, pageNo) {
  return request.axiosIns({
    url: `device-instance/_query?pageIndex=${pageNo}&pageSize=8&terms%5B0%5D.column=id%24dev-group&terms%5B0%5D.value=${id}`,
    method: 'get',
  })
}
/* 解除绑定（多个） */
export function unBinds(groupId, params) {
  return request.axiosIns({
    url: `device/group/${groupId}/_unbind`,
    method: 'post',
    data: params,
  })
}
/* 绑定设备（多个） */
export function binds(groupId, params) {
  return request.axiosIns({
    url: `device/group/${groupId}/_bind`,
    method: 'post',
    data: params,
  })
}
/* 查询分组可以绑定的设备（已经绑定的不查询） */
export function queryDevicesByGroupId(groupId, pageNo) {
  return request.axiosIns({
    url: `device-instance/_query?pageIndex=${pageNo}&pageSize=8&terms%5B0%5D.column=id%24dev-group%24not&terms%5B0%5D.value=${groupId}`,
    method: 'get',
  })
}
