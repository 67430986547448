<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      ref="cardAction"
      action-refresh
      no-body
    >
      <div class="pl-2 pr-2 mt-4">
        <b-row>
          <b-col
            cols="12"
          >
            <b-button
              id="btn-add-group"
              v-b-modal.modal-form
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              block
              variant="outline-primary"
              class="cx-dash-border mb-1 mt-1"
              @click="isNew = true"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />新增
            </b-button>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <el-table
          class="abc"
          :data="groupList"
          style="width: 100%;margin-bottom: 20px;"
          :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
          row-key="id"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <template slot="empty">
            <b-img center class="mb-5 mt-2" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
          </template>
          <el-table-column
            prop="id"
            label="标识"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            sortable
            width="180">
          </el-table-column>
          <el-table-column
            prop="description"
            label="描述">
          </el-table-column>
          <el-table-column
            prop="actions"
            label="操作">
            <template slot-scope="scope">
              <b-button
                :id="`group-row-${scope.row.id}-view-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-toggle.sidebar-right
                @click="toView(scope.row)">
                <feather-icon icon="EyeIcon" size="16" />
              </b-button>
              <b-tooltip
                title="查看"
                :target="`group-row-${scope.row.id}-view-icon`"
              />
              <b-tooltip
                title="编辑"
                :target="`group-row-${scope.row.id}-edit-icon`"
              />
              <b-button
                :id="`group-row-${scope.row.id}-edit-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-modal.modal-form
                @click="toEdit(scope.row)">
                <feather-icon icon="Edit3Icon" size="16" />
              </b-button>
              <b-button
                :id="`group-row-${scope.row.id}-remove-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-modal.modal-form
                @click="toEdit(scope.row)">
                <feather-icon icon="Trash2Icon" size="16" />
              </b-button>
              <b-tooltip
                title="删除"
                :target="`group-row-${scope.row.id}-remove-icon`"
              />
              <b-button
                :id="`group-row-${scope.row.id}-parent-icon`"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="btn-icon rounded-circle"
                variant="flat-primary"
                v-b-modal.modal-form
                @click="isNew = true,parentId = scope.row.id">
                <feather-icon icon="AnchorIcon" size="16" />
              </b-button>
              <b-tooltip
                title="添加下一级"
                :target="`group-row-${scope.row.id}-parent-icon`"
              />
              <x-popconfirm
                :target="`group-row-${scope.row.id}-remove-icon`"
                content="确定要删除该分组吗？"
                triggers="focus"
                @enter="remove(scope.row.id)"
              >
              </x-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-card>
    <b-modal
      id="modal-form"
      ref="my-modal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      :title="isNew? '新增': '编辑'"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="标识">
          <b-form-input
            v-model="formData.id"
            placeholder="请输入分组标识"
            :readonly="!isNew"
          />
        </x-form-feild>
        <x-form-feild label="名称" require>
          <b-form-input
            v-model="formData.name"
            placeholder="请输入分组名称"
          />
        </x-form-feild>
        <x-form-feild label="描述">
          <b-form-textarea
            v-model="formData.description"
            placeholder="请输入描述内容"
          />
        </x-form-feild>
      </x-form-validator>
    </b-modal>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="60%"
      title="设备绑定"
    >
      <group-details
        ref="form"
        :id="viewId"
        :group="group"
      />
    </b-sidebar>
  </div>
</template>

<script>
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  BCard, BModal, BRow, BCol, BFormInput, BButton, VBToggle, VBModal, BFormTextarea, BTooltip, BSidebar, BImg,
} from 'bootstrap-vue'
import {
  query, add, edit, remove,
} from '@/api/device-manage/device-group'
import {
  formatDate2,
} from '@/api/timeFormatUtils'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GroupDetails from './GroupDetails.vue'

export default {
  filters: {
    formatDate(time) {
      const date = new Date(time)
      return formatDate2(date, 'yyyy-MM-dd hh:mm:ss')
    },
  },
  components: {
    XFormValidator,
    XFormFeild,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormTextarea,
    BTooltip,
    XPopconfirm,
    GroupDetails,
    BSidebar,
    BImg,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      searchQuery: undefined,
      total: 0,
      currentPage: 1,
      perPage: 9,
      sortBy: 'id',
      isNew: true,
      searchName: undefined,
      groupList: [],
      formData: {
        id: undefined,
        name: undefined,
        description: undefined,
      },
      parentId: undefined,
      required,
      group: {},
      viewId: undefined,
    }
  },
  created() {
    this.page()
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.isNew) {
            if (this.parentId !== undefined) {
              this.formData.parentId = this.parentId
            }
            add(this.formData).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '新增分组成功',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.page()
            })
          } else {
            edit(this.formData).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: '编辑分组成功',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.page()
            })
          }
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#btn-add-group')
          })
        }
      })
    },
    toEdit(item) {
      this.isNew = false
      this.formData = this._.cloneDeep(item)
    },
    // 删除分组
    remove(id) {
      remove(id).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '删除分组成功',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.page()
      })
    },
    page() {
      query().then(resp => {
        this.groupList = resp.data.result
      })
    },
    resetModal() {
      this.formData = {
        id: undefined,
        name: undefined,
        description: undefined,
      }
      this.parentId = undefined
    },
    toView(item) {
      this.group = item
      this.viewId = item.id
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
