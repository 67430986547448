<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="pl-2 pr-2 mt-4">
          <b-row class="mb-2">
            <b-col cols="8">
              <div class="mt-1">
                <span>分组名称：</span><span>{{group.name}}</span>
              </div>
            </b-col>
            <b-col cols="4" class="d-flex justify-content-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="unBinds"
              >
                解绑已选
              </b-button>
              <b-button
                v-b-modal.modal-bind
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="ml-1"
                @click="initModal"
              >
                绑定设备
              </b-button>
            </b-col>
          </b-row>
          <!-- Table Top -->
          <el-table
            ref="deviceTable"
            :data="deviceList"
            style="width: 100%;margin-bottom: 20px;"
            :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
            row-key="id"
            @selection-change="selectCheckBox"
          >
            <el-table-column
              type="selection"
              color="red"
              width="55">
            </el-table-column>
            <el-table-column
              prop="id"
              label="标识"
              sortable
              width="180">
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              sortable
              width="180">
            </el-table-column>
            <el-table-column
              prop="productName"
              label="产品名称">
            </el-table-column>
            <el-table-column
              prop="state.value"
              label="状态">
              <template slot-scope="scope">
                <b-badge
                  variant="success"
                  v-if="scope.row.state.value === 'online'"
                >
                  在线
                </b-badge>
                <b-badge
                  variant="danger"
                  v-if="scope.row.state.value === 'offline'"
                >
                  离线
                </b-badge>
                <b-badge
                  variant="warning"
                  v-if="scope.row.state.value === 'notActive'"
                >
                  未启用
                </b-badge>
              </template>
            </el-table-column>
            <el-table-column
              prop="actions"
              label="操作">
              <template slot-scope="scope">
                <div class="btn btn-flat-primary btn-icon rounded-circle"
                     :id="`device-row-${scope.row.id}-view-icon`"
                     @click="toDeviceDetail(scope.row)">
                  <feather-icon
                    icon="EyeIcon"/>
                </div>
                <b-tooltip
                  title="详情"
                  :target="`device-row-${scope.row.id}-view-icon`"
                />
                <b-tooltip
                  title="解绑"
                  :target="`device-row-${scope.row.id}-unbind-icon`"
                />
                <div class="btn btn-flat-danger btn-icon rounded-circle"
                     :id="`device-row-${scope.row.id}-unbind-icon`"
                     @click="unBind(scope.row.id)">
                  <feather-icon
                    icon="XIcon"/>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">共{{total}}条记录</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-bind"
      ref="my-modal"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="绑定"
      cancel-title="取消"
      centered
      size="xl"
      title="绑定设备"
      @hidden="resetModal"
      @ok="handleOk"
    >
        <b-row>
          <b-col cols="12">
            <div class="pl-2 pr-2 mt-4">
              <el-table
                ref="bindTable"
                :data="toBindList"
                style="width: 100%;margin-bottom: 20px;"
                :header-cell-style="{background:'#f3f2f7',fontSize: '0.857rem', color: '#6e6b7b',textAlign: 'inherit', lineHeight: '15.5px !important', paddingTop: '2px',paddingBottom: '2px' }"
                row-key="id"
                @selection-change="selectCheckBoxBind"
              >
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="标识"
                  sortable
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="名称"
                  sortable
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="productName"
                  label="产品名称">
                </el-table-column>
                <el-table-column
                  prop="state.value"
                  label="状态">
                  <template slot-scope="scope">
                    <b-badge
                      variant="success"
                      v-if="scope.row.state.value === 'online'"
                    >
                      在线
                    </b-badge>
                    <b-badge
                      variant="danger"
                      v-if="scope.row.state.value === 'offline'"
                    >
                      离线
                    </b-badge>
                    <b-badge
                      variant="warning"
                      v-if="scope.row.state.value === 'notActive'"
                    >
                      未启用
                    </b-badge>
                  </template>
                </el-table-column>
              </el-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">共{{deviceTotal}}条记录</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="deviceCurrentPage"
                      :total-rows="deviceTotal"
                      :per-page="devicePerPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BBadge, BButton, BTooltip, VBToggle, BPagination,
} from 'bootstrap-vue'
import {
  getdeviceListByGroupId, unBinds, queryDevicesByGroupId, binds,
} from '@/api/device-manage/device-group'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,
    BButton,
    BTooltip,
    BPagination,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    group: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      deviceList: [],
      toBindList: [],
      // 解绑ids
      selectIds: [],
      // 选择绑定ids
      bindIds: [],
      total: 0,
      currentPage: 1,
      perPage: 8,
      deviceTotal: 0,
      deviceCurrentPage: 1,
      devicePerPage: 8,
      deviceId: undefined,
      productId: undefined,
    }
  },
  watch: {
    id(val) {
      this.page(val)
    },
    currentPage() {
      this.page(this.id)
    },
    deviceCurrentPage() {
      this.initModal()
    },
  },
  methods: {
    page(id) {
      this.deviceList = []
      getdeviceListByGroupId(id, this.currentPage - 1).then(resp => {
        this.deviceList = resp.data.result.data
        this.total = resp.data.result.total
      })
    },
    /* 多个接触绑定 */
    unBinds() {
      if (this.selectIds.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '请选择解绑设备',
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
        return
      }
      unBinds(this.id, this.selectIds).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '解除绑定成功',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.selectIds = []
        this.page(this.id)
      })
    },
    /* 单独解绑一个 */
    unBind(id) {
      this.selectIds.push(id)
      unBinds(this.id, this.selectIds).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '解除绑定成功',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.selectIds = []
        this.page(this.id)
      })
    },
    // 加载设备列表选择绑定
    initModal() {
      queryDevicesByGroupId(this.id, this.deviceCurrentPage - 1).then(resp => {
        this.toBindList = resp.data.result.data
        this.deviceTotal = resp.data.result.total
      })
    },
    // 绑定设备modal关闭
    resetModal() {
      this.toBindList = []
    },
    // 绑定设备
    handleOk() {
      binds(this.id, this.bindIds).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '绑定设备成功',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.bindIds = []
        this.page(this.id)
      })
    },
    // 接触绑定设备数组构建
    selectCheckBox(selection) {
      this.selectIds = []
      selection.forEach(item => {
        this.selectIds.push(item.id)
      })
    },
    // 即将绑定设备数组构建
    selectCheckBoxBind(selection) {
      this.bindIds = []
      selection.forEach(item => {
        this.bindIds.push(item.id)
      })
    },
    // 跳转设备详情页面
    toDeviceDetail(item) {
      this.$router.push({
        name: 'ec-device-view',
        params: { id: item.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  [dir] .el-checkbox__input.is-checked .el-checkbox__inner, [dir] .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #7367f0;
    border-color: #7367f0;
  }
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
